.swiper {
    width: 100%;
    height: 100%;
    padding: 0px;
    overflow-x: hidden;
}


.swiper-slide {
    border-radius: 0rem;
    height: 100%;
}

