.section7_bg::before {
    content: "";
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    position: absolute;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    background-image: url("https://images.pexels.com/photos/2832382/pexels-photo-2832382.jpeg?auto=compress&cs=tinysrgb&w=600");

}

.section7_bg::after {
    content: "";
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0.7;
    background: #373B44;
    background: -webkit-linear-gradient(to right, #4286f4, #373B44);
    background: linear-gradient(to right, #4286f4, #373B44);
}