#app {
    height: 100%
}

html,
body {
    position: relative;
    height: 100%;
}

.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 500px;
    border-radius: 20px;
    overflow: hidden;
}

.swiper-slide img {
    display: block;
    height: 100%;
}